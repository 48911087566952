<template>
  <div class="app-mall-cart container">
    <div class="content ex padding-20-0" v-loading="loading">
      <el-steps :active="0" process-status="success" simple>
        <el-step title="我的购物车" />
        <el-step title="填写核对订单信息" />
        <el-step title="成功提交订单" />
      </el-steps>
      <template v-if="store && store.length">
        <el-table class="hideEmptyRow">
          <el-table-column width="40" />
          <el-table-column width="100" />
          <el-table-column label="商品" min-width="360" />
          <el-table-column label="单价" align="right" width="150" />
          <el-table-column label="数量" align="center" width="100" />
          <el-table-column label="小计" align="right" width="120" />
          <el-table-column width="60" />
        </el-table>
        <el-table :data="store" :show-header="false" row-key="id">
          <el-table-column width="40">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row._checked" />
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot-scope="scope">
              <div class="cart-thumbnail" :style="{backgroundImage: `url(${scope.row.coverImg || '/static/img/no-image.png'})`}"></div>
            </template>
          </el-table-column>
          <el-table-column class-name="el-table--inner-column transparent">
            <template slot-scope="scope">
              <template v-if="scope.row.type === 'sku'">
                <el-table :data="scope.row.items" row-key="id" :show-header="false" class="el-table--inner">
                  <el-table-column label="商品" min-width="360">
                    <div class="lh-150" slot-scope="item">
                      <div>
                        <a :href="`/mall/item/${encode(item.row.spuId)}`" target="dup">
                          <b>{{item.row.name}}</b>
                        </a>
                      </div>
                      <div class="fc-g">{{$goodsSpecConvert(item.row.specs)}}</div>
                    </div>
                  </el-table-column>
                  <el-table-column prop="_price" label="单价" align="right" width="150" :formatter="$price" />
                  <el-table-column label="数量" align="center" width="100">
                    <template slot-scope="item">
                      <el-input-number size="mini" v-model="scope.row.times" :min="item.row.minSalesQuantity || 1" :max="9999" :step="1" :precision="0" controls-position="right" @change="$checkNaN(scope.row, 'times', item.row.minSalesQuantity || 1)" style="width: 100%" />
                    </template>
                  </el-table-column>
                  <el-table-column label="小计" align="right" width="120">
                    <template slot-scope="item">{{$price(item.row._price * scope.row.times * item.row._quantity)}}</template>
                  </el-table-column>
                  <el-table-column width="60">
                    <div class="row-commands" slot-scope="item">
                      <el-button size="mini" type="text" @click="handleCartRemove(scope.row)">移除</el-button>
                    </div>
                  </el-table-column>
                </el-table>
              </template>
              <template v-else-if="scope.row.type === 'group'">
                <div class="h c padding-10-0">
                  <div class="flex lh-150 padding-0-10">
                    <a :href="`/mall/group/item/${encode(scope.row.groupSaleId)}`" target="dup">
                      <b>{{scope.row.name}}</b>
                    </a>
                    <el-tag size="mini">组合销售</el-tag>
                  </div>
                  <div class="padding-0-10">
                    <el-input-number size="mini" v-model="scope.row.times" :min="scope.row.minTimes" :max="9999" :step="1" :precision="0" :controls="false" @change="$checkNaN(scope.row, 'times', scope.row.minTimes)" style="width: 100px;" />
                  </div>
                  <div style="width: 120px"></div>
                  <div class="row-commands padding-0-10" style="width: 60px;">
                    <el-button size="mini" type="text" @click="handleCartRemove(scope.row)">移除</el-button>
                  </div>
                </div>

                <el-table :data="scope.row.items" row-key="id" :show-header="false" class="el-table--inner">
                  <el-table-column label="商品" min-width="360">
                    <div class="lh-150" slot-scope="item">
                      <div>
                        <a :href="`/mall/item/${encode(item.row.spuId)}`" target="dup">{{item.row.name}}</a>
                      </div>
                      <div class="fc-g">{{$goodsSpecConvert(item.row.specs)}}</div>
                    </div>
                  </el-table-column>
                  <el-table-column label="单价" align="right" width="150">
                    <div class="lh-150 v e" slot-scope="item">
                      <b>{{$price(item.row._price)}}</b>
                      <span class="fc-g through" v-if="item.row._price > item.row._price">{{$price(item.row._price)}}</span>
                    </div>
                  </el-table-column>
                  <el-table-column label="数量" align="center" width="100">
                    <template slot-scope="item">{{scope.row.times * item.row._quantity}}</template>
                  </el-table-column>
                  <el-table-column label="小计" align="right" width="120">
                    <template slot-scope="item">{{$price(item.row._price * scope.row.times * item.row._quantity)}}</template>
                  </el-table-column>
                  <el-table-column width="60" />
                </el-table>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <div class="confirm-info">
          <div class="h c">
            <div class="padding-0-10 flex h c">
              <el-checkbox v-model="selectAll" @change="handleSelectAllChange">全选</el-checkbox>
              <el-divider direction="vertical" />
              <el-button type="text" :disabled="!counts._nums" @click="handleCartClear(false)">移除所选商品</el-button>
              <el-divider direction="vertical" />
              <el-button type="text" @click="handleCartClear(true)">清空购物车</el-button>
            </div>
            <div class="fc-g padding-10 h c">
              已经选择&nbsp;
              <b class="fc-e">{{counts._nums}}</b>&nbsp;件商品
              <el-divider direction="vertical" />总价:
              <b class="fc-e fs-large">￥{{(counts._price / 100).toFixed(2)}}</b>
            </div>
            <el-button class="no-radius" size="large" type="danger" :disabled="!counts._nums" @click="handleSubmit">
              <b class="fs-huge">去结算</b>
            </el-button>
          </div>
        </div>
      </template>
      <template v-else-if="!loading">
        <div class="v c padding-20">
          <div class="padding-10">目前购物车还没有任何商品</div>
          <el-button type="primary" @click="handleList">前去逛逛</el-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getSkuRetailPrice } from "@/api/goods";

export default {
  props: {
    shopId: Number | String
  },
  data() {
    return {
      loading: false,
      selectAll: false,
      store: []
    };
  },
  computed: {
    counts() {
      let _nums = 0,
        _price = 0,
        count = 0;
      this.store.forEach(o => {
        if (o._checked) {
          count += 1;
          o.items.forEach(g => {
            let n = g._quantity * o.times;
            _price += (g._price || 0) * n;
            _nums += n;
          });
        }
      });
      this.selectAll = count === this.store.length;
      return {
        _nums: _nums,
        _price: _price
      };
    }
  },
  methods: {
    loadCart() {
      if (window.mall) {
        this.store = window.mall.cart.get();
        (this.store || []).forEach(o => {
          o.items.forEach(g => {
            this.loadGoodsPrice(o, g);
          });
        });
      }
    },
    loadGoodsPrice(o, g) {
      if (this.shopId && o && g && g.id) {
        getSkuRetailPrice(this.shopId, g.id)
          .then(res => {
            g._price = res;
          })
          .catch(err => {
            g._price = null;
          });
      }
    },
    encode(val) {
      return btoa(val);
    },
    handleCartRemove(item) {
      window.mall.cart.remove(item);
      this.loadCart();
    },
    handleCartClear(clearAll = true) {
      this.$confirm(
        clearAll ? "您确定要清空购物车吗？" : "您确定要移除勾选的商品吗？",
        "操作确认",
        {
          type: "warning"
        }
      ).then(_ => {
        window.mall.cart.clear(clearAll);
        this.loadCart();
      });
    },
    handleList() {
      this.$router.push({
        path: "/mall/retail/list"
      });
    },
    handleSelectAllChange(checked) {
      this.store.forEach(o => {
        o._checked = checked;
      });
    },
    handleSubmit() {
      let items = [];
      let now = new Date(this.$now.get());
      this.store.forEach(o => {
        if (o._checked) {
          let gid = this.$uuid();
          o.items.forEach(g => {
            let _no = {
              id: this.$uuid(),
              discountRate: 10000,
              spuId: g.spuId,
              goodsId: g.id,
              brandId: g.brandId,
              brandName: g.brandName,
              seriesId: g.seriesId,
              seriesName: g.seriesName,
              goodsName: g.name,
              specs: g.specs,
              price: g._price,
              realPrice: g._price,
              code: g.code,
              erpCode: g.erpCode,
              quantity: o.times * g._quantity,
              cancelCount: 0,
              sendType: 1,
              discountAmount: 0,
              deliveryCycle: o.deliveryCycle || 7,
              info: ""
            };
            if (o.type === "group") {
              _no.groupId = gid;
              _no.promotionId = g._rule.promotionId;
              _no.groupSaleId = g._rule.groupSalesItemId;
              _no.groupSaleName = o.name;
              _no.promotionJoinType = "groupSale";
              _no.groupMinBuyQuantity = o.minTimes;
            }
            items.push(_no);
          });
        }
      });
      if (items && items.length) {
        window.localStorage.setItem(
          "retail-cart-confirm",
          JSON.stringify(items)
        );
        this.$router.push({
          path: "/mall/retail/cart/confirm"
        });
      }
    }
  },
  deactivated() {
    window.mall.cart.save();
  },
  mounted() {
    this.$nextTick(_ => {
      this.loadCart();
    });
  },
  activated() {
    this.loadCart();
  }
};
</script>